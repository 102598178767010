.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.modal-content {
  background: #ffffff;
  padding: 20px;
  width: 80%;
  max-width: 500px;
  border-radius: 8px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
}

.error-button {
  padding: 8px 16px;
  margin-top: 10px;
  border: none;
  background-color: purple;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 700;
}

.error-button:hover {
  background-color: rgb(104, 0, 104);
}
