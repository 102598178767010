.container {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.stored-routes-table table {
  width: 100%;
  border-collapse: collapse;
}

.stored-routes-table th,
.stored-routes-table td {
  border-bottom: 1px solid #ccc;
  padding: 15px 8px;
  text-align: left;
}

.stored-routes-table th {
  background-color: #f2f2f2;
}

.stored-routes-table th:nth-child(n + 3):nth-child(-n + 7),
.stored-routes-table td:nth-child(n + 3):nth-child(-n + 7) {
  text-align: center;
}

.container .stored-routes-table table th:nth-child(1) {
  width: 200px;
}

.container .stored-routes-table table th:nth-child(2) {
  width: 300px;
}

.container .stored-routes-table table th:nth-child(3) {
  width: 200px;
}

.container .stored-routes-table table th:nth-child(4) {
  width: 150px;
}

.container .stored-routes-table table th:nth-child(5) {
  width: 150px;
}

.container .stored-routes-table table th:nth-child(6) {
  width: 150px;
}

.container .stored-routes-table table th:nth-child(7) {
  width: 150px;
}

.eye-icon {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  background-image: url("../../assets/eye.png");
  background-repeat: no-repeat;
  background-position: center;
  padding: 0;
  height: 30px;
  width: 30px;
  background-size: 25px 25px;
  min-width: 40px;
  position: relative;
}

.eye-icon:hover::before {
  content: attr(data-tooltip);
  position: absolute;
  top: -14px;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px 10px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  white-space: nowrap;
  font-size: 12px;
  pointer-events: none;
  z-index: 1;
}

.delete-icon:before {
  content: "×";
  cursor: pointer;
  font-weight: bold;
  color: #888;
  padding: 0;
  font-size: 24px;
}

.route-details {
  height: 250px;
}

.button-container {
  padding-top: 20px;
  align-self: flex-end;
}

.button {
  padding: 6px 18px;
  background-color: purple;
  color: white;
  font-weight: 700;
  border-radius: 6px;
}

.arrow {
  display: inline-block;
  margin-left: 5px;
  font-size: 18px;
  font-weight: 700;
}

.red.up::before {
  content: "↑";
  color: rgb(165, 18, 18);
}

.green.down::before {
  content: "↓";
  color: rgb(16, 117, 16);
}
