.map-container {
  width: 100%;
  margin: 20px 40px;
  border: 1px solid green;
  position: relative;
}

.tolls-toggle {
  position: absolute;
  bottom: 18px;
  right: 0px;
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  z-index: 400;
  display: flex;
  align-items: center;
}

.checkbox {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
