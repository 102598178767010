.legend {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(255, 255, 255, 0.6);
  padding: 10px 10px 5px;
  border-radius: 4px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  z-index: 400;
}

.legendItem {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  justify-content: space-between;
}

.legendColor {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 10px;
}
