* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

button {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style-type: none;
}

img {
  max-width: 100%;
  display: block;
}

table {
  width: 100%;
  border-collapse: collapse;
}

input:focus,
select:focus {
  outline: none;
  border-color: #66cc33;
  box-shadow: 0 0 5px #66cc33;
}

.container {
  width: 100vw;
  height: 100vh;
  background-image: url("./assets/background.png");
  background-size: cover;
  overflow: hidden;
}

.button-group {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: -20px;
  background-color: purple;
  border-radius: 8px;
  margin-left: auto;
  width: min-content;
  z-index: 1001;
  position: relative;
  box-shadow: 4px 4px 6px 0 rgba(0, 0, 0, 0.35);
}

.page-button {
  height: 50px;
  width: 200px;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
}

.internal-container {
  display: flex;
  flex-direction: column;
  width: 80vw;
  height: 83vh;
  overflow: scroll;
  margin: 15px auto;
  background-color: #fff;
  border-radius: 10px;
  border: 2px solid #66cc33;
  box-shadow: 4px 4px 6px 0 rgba(0, 0, 0, 0.35);
  margin-top: -20px;
  position: relative;
}

.shipping-results-container {
  display: flex;
  height: 100%;
}

.summary-tables {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.summary-tables-container:nth-child(2) {
  margin-top: 70px;
}

.map-container {
  width: 50%;
  display: flex;
  justify-content: center;
  margin-top: 26px;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

* {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
