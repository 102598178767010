.header-container {
  width: 100%;
  height: 130px;
  background-color: #fff;
}

.logo-image {
  height: 100%;
  margin: 0 auto;
}
