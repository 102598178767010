.container {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 26px 26px 0;
}

.toll-container {
  width: 100%;
  padding: 0 26px;
}

.title {
  text-align: center;
  font-weight: 700;
  margin-bottom: 10px;
}

.map-container {
  width: 50%;
}

.summary-table {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 20px;
}

.row {
  display: flex;
  justify-content: space-between;
}

.row-key {
  font-weight: 700;
  font-size: 18px;
}

.row-value {
  font-weight: 700;
  font-size: 18px;
}

.tolls-table {
  display: flex;
  flex-direction: column;
  border: 1px solid #a0a0a0;
  border-radius: 6px;
  margin-top: 20px;
  position: relative;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
  max-height: 210px;
  overflow-y: scroll;
  position: absolute;
  width: -webkit-fill-available;
  z-index: 10;
  background-color: white;
  margin-right: 52px;
}

.toll-row {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #a0a0a0;
  padding: 11px 14px;
}

.toll-row:first-child {
  font-weight: 700;
  cursor: pointer;
}

.toll-row:last-child {
  border-bottom: none;
}

.arrow {
  position: absolute;
  right: 10px;
  top: 16px;
  cursor: pointer;
}

.arrow.rotated {
  transform: rotate(180deg);
}

.toll-row-key-pair {
  display: flex;
  flex-direction: column;
  color: #a0a0a0;
}

.toll-row-key-name {
  font-weight: 700;
}

.toll-row-key-location {
  font-size: 12px;
}

.toll-row-value {
  color: #a0a0a0;
  font-weight: 700;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.toll-row-via-facil-id {
  margin-right: auto;
  margin-left: 20px;
  color: #888;
  font-weight: 700;
}
