.container-shipping {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: 20px;
  padding: 26px 55px;
  border-bottom: 1px solid #bbb;
}

.container-storage {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 26px 55px;
  border-bottom: 1px solid #bbb;
}

.input-box-origin-shipping {
  grid-column: 1;
  grid-row: 1;
  position: relative;
}

.input-box-destination-shipping {
  grid-column: 1;
  grid-row: 2;
  position: relative;
}

.input-box-axes-shipping {
  grid-column: 2;
  grid-row: 1/3;
  width: 160px;
  align-self: center;
  justify-self: center;
  margin-bottom: 25px;
}

.button-container-shipping {
  grid-column: 3;
  grid-row: 1/3;
  align-self: center;
  justify-self: center;
}

.input-label {
  margin-bottom: 8px;
  text-align: center;
}

.input-box-origin-storage {
  width: 20%;
}

.input-box-destination-storage {
  width: 20%;
  position: relative;
}

.input-box-axes-storage {
  width: 20%;
}

.input-box-client {
  width: 20%;
  position: relative;
}

.input-box-tonnage {
  width: 20%;
  position: relative;
}

.button-container-storage {
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

select,
input {
  width: 100%;
  height: 50px;
  border-radius: 6px;
  border: 1px solid #999;
  font-size: 16px;
  text-align: center;
}

.circle {
  width: 14px;
  height: 14px;
  border: 1px solid black;
  border-radius: 50%;
  background-color: transparent;
  position: absolute;
  left: -30px;
  top: 43px;
}

.mini-circle1 {
  width: 2px;
  height: 2px;
  border: 1px solid black;
  border-radius: 50%;
  background-color: transparent;
  position: absolute;
  left: -24px;
  top: 75px;
}

.mini-circle2 {
  width: 2px;
  height: 2px;
  border: 1px solid black;
  border-radius: 50%;
  background-color: transparent;
  position: absolute;
  left: -24px;
  top: 95px;
}

.mini-circle3 {
  width: 2px;
  height: 2px;
  border: 1px solid black;
  border-radius: 50%;
  background-color: transparent;
  position: absolute;
  left: -24px;
  top: 115px;
}

.pin {
  height: 34px;
  width: 34px;
  position: absolute;
  left: -40px;
  top: 35px;
}

.button {
  padding: 10px 28px;
  color: #f5f5f5;
  font-size: 20px;
  font-weight: bold;
  background-color: #66cc33;
  border-radius: 6px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.shipping-button {
  border: 1px solid #666;
  height: fit-content;
  width: fit-content;
  align-self: center;
  justify-self: center;
}

.storage-button {
  height: 45px;
  width: 50%;
  align-self: center;
  justify-self: center;
}

.suggestionsDropdown {
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  z-index: 1;
  top: 75px;
  background-color: white;
}

.suggestionItem {
  padding: 8px;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
}

.input-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 6px;
  align-items: center;
  position: relative;
}

.destination {
  padding-left: 8px;
  text-align: start;
}

.axes {
  padding-left: 12px;
}

.arrow {
  position: absolute;
  right: 10px;
  top: 46px;
  cursor: pointer;
}

.arrow.rotated {
  transform: rotate(180deg);
}

.origin,
.axes {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  background-image: url("../../assets//down-arrow.svg");
  background-repeat: no-repeat;
  background-position: right 0.5em center;
  padding-right: 1.5em;
}

.origin,
.client,
.tonnage {
  text-align: start;
  padding-left: 8px;
}

.destinationWrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}

.clearButton {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #999;
  font-size: 24px;
}
