.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
}

.modal-content {
  background: white;
  padding: 20px;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
  position: relative;
  width: 800px;
  border-radius: 10px;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
}

.header {
  display: flex;
  width: 100%;
  gap: 40px;
  justify-content: center;
  font-weight: 700;
}

.subheader {
  display: flex;
  gap: 10px;
}

.table-wrapper {
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin: 20px 40px;
}

.comparison-table {
  width: 100%;
  border-collapse: collapse;
}

.comparison-table thead {
  background-color: #f7f7f7;
}

.comparison-table th,
.comparison-table td {
  padding: 12px 15px;
  text-align: center;
}

.comparison-table td {
  font-weight: 700;
}

.green {
  color: rgb(16, 117, 16);
}

.red {
  color: rgb(165, 18, 18);
}
